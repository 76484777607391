<template>
  <div id="app"
       style="min-height: 100vh; background-color: #F8F8F8; padding-top: 20px; padding-left: 3vw; padding-right: 3vw;">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="data.length > 0"
    >
      <div v-for="(item, index) in data" :key="index"
           style="margin-bottom: 12px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);">
        <div style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; padding-left: 12px;">
          <div style="height: 45px; border-radius: 7px 7px 0px 0px; display: flex;">
            <span style="flex: 1; color: #333333; line-height: 45px; ">{{ item.name }}</span>
            <span style="color: #34AC40; line-height: 45px; margin-right: 12px;"
                  v-if="item.state === 'ENABLED'">启用</span>
            <span style="color: #888888; line-height: 45px; margin-right: 12px;"
                  v-if="item.state === 'DISABLED'">禁用</span>
          </div>

<!--          <div v-if="item.state === 'DISABLED'">-->
<!--            <div style="display: flex; height: 14px; line-height: 14px; ">-->
<!--              <div style="color: #888888">禁用人:</div>-->
<!--              <div style="margin-left: 6px">谭校长</div>-->
<!--            </div>-->

<!--            <div style="display: flex; height: 14px; line-height: 14px; margin-top: 16px; margin-bottom: 16px;">-->
<!--              <div style="color: #888888">禁用时间:</div>-->
<!--              <div style="margin-left: 6px">{{ (item.updatedTime) | moment('yyyy-MM-DD HH:mm') }}</div>-->
<!--            </div>-->
<!--          </div>-->

          <div style="background-color: #F2F2F2; height: 1px;"></div>

          <div style="display: flex; height: 50px;" v-if="item.role != 'KITCHEN_MANAGE' && canHandleOperator">
            <span style="flex: 1;"></span>

            <div
                style="height: 32px; line-height: 32px; text-align: center; color: #888888; border-radius: 4px; border: 1px solid #888888; padding-right: 8px; padding-left: 8px; align-self: center; margin-right: 12px;"
                v-if="item.state === 'ENABLED'"
                @click="forbiddenBusinessUser(item)">
              立即禁用
            </div>

            <div
                style="background: linear-gradient(90deg, #39D148, #34AC40); height: 32px; line-height: 32px; text-align: center; color: #FFFFFF; border-radius: 4px; padding-right: 8px; padding-left: 8px; align-self: center; margin-right: 12px;"
                v-if="item.state === 'DISABLED'"
                @click="reopenBusinessUser(item)">
              立即启用
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div v-if="data.length === 0 && !loading"
         style="display: grid; justify-items: center; position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <img
          style="width: 64px; height: 62px; "
          :src="require('../../../src/assets/images/kitchen/icon_none.png')"
          alt=""/>
      <span style="font-size: 18px; color: #34AC40; margin-top: 10px;">暂无数据</span>
      <span style="font-size: 14px; color: #999999; margin-top: 8px;">用户列表为空</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {KITCHEN_BUSINESS_ID, KITCHEN_USER_ROLE} from "../../store/mutation-types";
import {post} from "../../utils/httpKitchen";
import {Dialog, Notify} from "vant";

export default {
  name: "UserList",
  data() {
    return {
      loading: false,
      finished: false,
      data: [],
      canHandleOperator: false,
      load: {
        page: 0,
        size: 20,
      }
    }
  },
  mounted() {
    const kitchenUserRole = Vue.ls.get(KITCHEN_USER_ROLE);
    if (kitchenUserRole === 'KITCHEN_MANAGE') {
      this.canHandleOperator = true;
    } else {
      this.canHandleOperator = false;
    }

    this.onLoad();
  },
  methods: {
    onLoad() {
      this.loading = true;
      post('api/kitchen/app/v1/user/search', {
        page: this.load.page,
        size: this.load.size,
        sort: 'createdTime DESC'
      })
          .then(res => {
            this.load.page = this.load.page + 1;

            this.data = this.data.concat(res.data.rows)

            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.data.length >= res.data.total) {
              this.finished = true;
            }
          })
          .catch(error => {

          });

    },
    reopenBusinessUser(businessUser) {
      Dialog.confirm({
        message: '您确定启用[' + businessUser.name + ']吗？'
      }).then(() => {
        this.requestUpdateBusinessUserState(businessUser.id, 'ENABLED');
      });

    },
    forbiddenBusinessUser(businessUser) {
      Dialog.confirm({
        message: '您确定禁用[' + businessUser.name + ']吗？'
      }).then(() => {
        this.requestUpdateBusinessUserState(businessUser.id, 'DISABLED');
      });

    },
    requestUpdateBusinessUserState(id, state) {
      const businessId = Vue.ls.get(KITCHEN_BUSINESS_ID);
      post('api/kitchen/app/v1/user/{id}/update/state'
              .replace('{id}', id)
          , {
            state: state
          })
          .then(res => {
            this.loading = true;
            this.load.page = 0;
            this.data = [];
            this.onLoad();
          })
          .catch(error => {
            Notify({type: 'danger', message: '操作失败, 请重试'});
          });
    }
  }
}
</script>

<style scoped>

</style>